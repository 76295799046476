<template>
    <nav  class="navbar navbar-expand-lg navbar-light fixed-top" style="background-color: #FFFFFF;" v-bind:class=" { 'navbarOpen': show }">
        <div class="container">
            <a class="navbar-brand" style="width : 150px; height : 66px;" href="/">
                <img class="logo img-fluid " src="../assets/images/Asset 2252.png" >
            </a> <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"  aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" @click.stop="toggleNavbar()" >
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent"   v-bind:class="{ 'show': show }" >
                <div class=" d-flex align-items-end flex-column flex-md-wrap  justify-content-xl-end" style="width:100%;">
                    <ul class="navbar-nav text-end px-0 px-md-0 mx-0 py-2 py-md-2 py-lg-0">
                        <li class="nav-item  px-1 px-md-1 px-lg-3 py-1 py-md-1 py-lg-0">
                            <router-link class="linkNav" style="font-weight : 600;" to="/" :class="{ 'active': $route.name == 'Home' }">HOME</router-link>
                        </li>
                        <li class="nav-item px-1 px-md-1 px-lg-3 py-1 py-md-1 py-lg-0" >
                            <router-link class="linkNav" style="font-weight : 600;" to="/service" :class="{ 'active': $route.name == 'service' }">SERVICE</router-link>
                        </li>
                        <li class="nav-item px-1 px-md-1 px-lg-3 py-1 py-md-1 py-lg-0">
                            <router-link class="linkNav" style="font-weight : 600;" to="/project" :class="{ 'active': $route.name == 'project' }">OUR PROJECT</router-link>
                        </li>
                        <li class="nav-item px-1 px-md-1 px-lg-3 py-1 py-md-1 py-lg-0">
                            <router-link class="linkNav" style="font-weight : 600;" to="/contact" :class="{ 'active': $route.name == 'contact' }">CONTACT US</router-link>
                        </li>
                         <!-- <li class="nav-item px-1 px-md-1 px-lg-3 py-1 py-md-1 py-lg-0">
                            <a class="linkNav active" href="https://api.whatsapp.com/send?phone=6281280075188&text=Halo,%20Saya%20Tertarik%20Dengan%20Polargate%20Studio%20dari%20Website">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                                </svg>
                                0812 80075188
                            </a>
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
  template: '#navBar',
  props: {
    msg: String
  },
  data() {
    return {
      show: false,
    }
  },
  methods: {
    toggleNavbar() {
      this.show = !this.show
    }
  },


}
</script>

<style scoped>
.linkNav {
    color: black;
    text-decoration: none;
    font-family: 'Avenir LT Std';
    font-size: 16px;
    
    
}
.active{
    color: #058CFF;
}

.logo{
    width : 180px;
    height: auto;
    margin-top: 10px;
    
}
@media only screen and (max-width: 1000px) {
    .logo{
            width: 135px;
            height: auto;
            margin-top: 10px;
    }
}

@media only screen and (max-width: 700px) {
    .logo{
            width: 105px;
            height: auto;
            margin-top: 10px;
        }
}

.contoh{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: coral;
    font-weight: 900;
    margin: 15px;
    font-size: 23px;
    font-style: italic;
}

.mmas{
    font: optional;
    counter-reset: none;
    margin-block-start: 19px;
    display: block;
    font: 1em sans-serif;
    range: auto;
    width: 100%;
    height: auto;
    bottom: 160px;
    top: 160px;
    right: 160px;
    left: 160px;
    border-radius: 6mm;
}
</style>