import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        component: () =>
            import ('../views/About.vue')
    },
    {
        path: '/service',
        name: 'service',
        component: () =>
            import ('../views/Service.vue')
    },
    {
        path: '/project',
        name: 'project',
        component: () =>
            import ('../views/Project.vue')
    },
    {
        path: '/contact',
        name: 'contact',
        component: () =>
            import ('../views/Contact.vue')
    },
    {
        path: '/detailproject',
        name: 'detailproject',
        component: () =>
            import ('../views/DetailProject.vue')
    },
    {
        path: '/detailservice',
        name: 'detailservice',
        component: () =>
            import ('../views/DetailService.vue')
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
export default router