<template>
  <div class="home my-5">
  <!-- <Header/> -->
    <div class="container-fluid my-5">
      <div class="container home-margin">
        <div class="row d-flex justify-content-between ">
          <div class="col-12 col-md-4 col-lg-4 text-start mt-5 pt-5">
            <h2 class="home-video">Video</h2>
            <h2 class="home-pro">Production</h2><br>
            <h4 class="home-tittle">Call us today to see how we can help you on your next project.</h4> <br>
            <img src="../assets/images/home/Asset 540.png" alt="">
          </div>
          <div class="col-12 col-md-8 col-lg-8 mt-5 mt-lg-0">
            <img src="../assets/images/home/Asset 2608.png" width="100%" alt="">
          </div>
        </div>
        <div class="row mt-5 d-flex justify-content-center">
          <div class="col-12 col-md-12 col-lg-6 text-center">
            <h4 class="home-content">We are company based on Indonesia that support makes video and multimedia for marketing purpose to make your company grow faster. </h4>
          </div>
        </div>
      </div>
    </div>

    <!-- <Satu/> -->
    <HomeService/>
    <HomeProject/>
  <!-- <Footer/> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import Header from '@/components/Header.vue'
import HomeProject from '@/components/HomeProject.vue'
import HomeService from '@/components/HomeService.vue'
// import Footer from '@/components/Footer.vue'
// import Satu from '@/components/Satu.vue'

export default {
  
    mounted () {
    window.scrollTo(0, 0)
  },


  name: 'Home',
  components: {
    // Header,
    HomeProject,
    HomeService,
    // Footer,
    // Satu
  }
}
</script>

<style scoped>
.home-video{
  font-family: 'Avenir LT Std';
  color: #058CFF;
  font-weight: 900;
}
.home-pro{
  font-family: 'Avenir LT Std';
  color: #012478;
  font-weight: 900;
}
.home-title{
  font-family: 'Gotham*';
}
.home-content{
  font-family: 'Gotham*';
  color: #058CFF;
}

.home-margin{
   padding-top: 100px;
}

@media only screen and (max-width: 1000px) {
    .home-margin{
    padding-top: 95px;
    }
}
@media only screen and (max-width: 700px) {
    .home-margin{
    padding-top: 50px;
    }
}
</style>