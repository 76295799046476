<template>
  <div id="app" class="margin-app" >
    <Header/>
      <ButtonWhatsapp/>
      <router-view></router-view>
      <div class="row">
        <div class="col-12 d-none d-md-block d-lg-block">
        <a href="https://api.whatsapp.com/send?phone=6281280075188&text=Halo,%20Saya%20Tertarik%20Dengan%20Polargate%20Studio%20dari%20Website" class="float-wa" >
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="50%" fill="currentColor" class="bi bi-chat-dots" viewBox="0 0 16 16">
            <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
            <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z"/>
        </svg>
        </a>
        </div>
      </div>
    <Footer/>
      <div class="container-fluid"  style="background-color: #058CFF; width:100%">
        <div class="col-12 text-end copy" >
            <p class="mx-3"><small> 2020 © POLARGATE. ALL RIGHTS RESERVED.</small></p>
        </div>    
      </div>
  </div>
</template>

<style>
.float-wa {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 50px;
    right: 40px;
    background-color: #058CFF;
    color: #FFFFFF;
    border-radius: 50px;
    text-align: center;
    font-size: 2.1em;
    box-shadow: 2px 2px 8px #999;
    z-index: 100;
}

.margin-app{
  margin-bottom: -18px;
}
.copy{
  font-family: 'Avenir LT Std';
  color: #FFFFFFFF;
}
</style>

<script>
 import ButtonWhatsapp from './components/ButtonWhatsapp.vue'
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'


export default {
  components: { 
    ButtonWhatsapp, 
    Header, 
    Footer
      },
  
}
</script>